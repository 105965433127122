<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { getUserSupplierList } from '@/api/userCenter';
export default {
  name: 'App',
  created () {
    this.initPage();
  },
  methods: {
    initPage () {
      if (localStorage.getItem('token')) {
        this.getUserSupplierList();
      } else {
        sessionStorage.clear();
        localStorage.clear();
      }
    },
    getUserSupplierList () {
      getUserSupplierList({ pageFlag: false, deleteFlag: false }).then(
        (res) => {
          let companyList = res.body.list || [];
          this.$store.dispatch('changeAllCompanyLists', companyList);
          let currentSupplierId
            = localStorage.getItem('currentSupplierId') || '';
          if (currentSupplierId) {
            let supplier = companyList.filter(
              (item) => item.supplierId === currentSupplierId,
            )[0];
            this.$store.dispatch('changeUserInfo', supplier);
          }
        },
      );
    },
  },
};
</script>
<style>
html,
body {
  width: 100%;
  height: 100%;
  min-width: 1366px;
}

@media (min-width: 320px) and (max-width: 640px) {
  html,
  body {
    width: 100vw;
    height: 100vh;
    min-width: 100vw;
  }
}

* {
  padding: 0;
  margin: 0;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  /* font-family: PingFangSC-Medium, PingFang SC; */
  /* font-family: "Arial","Microsoft YaHei","黑体","宋体",sans-serif; */
  font-family: Helvetica, "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑",
    Arial, sans-serif, "PingFangSC-Medium", "PingFang SC";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>
