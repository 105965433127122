export default {
  name: 'table_pagination_mixin',
  data: () => {
    return {
      pagination: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      updateSortInfo: {
        orderByProperty: 'lastUpdateTime',
        orderByType: 'DESC',
      },
      createTimeSortInfo: {
        orderByProperty: 'createTime',
        orderByType: 'DESC',
      },
    };
  },
  created () {
    this.getTableList && this.getTableList();
  },
};
