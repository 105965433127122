<template>
    <div class="bottom_panel">
        <div class="bottom_panel--placeholder"></div>
        <div :class="className">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
  name: 'bottom-panel',
  props: {
    direction: {
      type: String,
      default: () => {
        return 'right';
      },
    },
  },
  data () {
    return {
      className: ['bottom_panel--content'],
    };
  },
  methods: {
    handleMenuCollapsChanged (v) {
      if (v) {
        this.className.push('isCollapsed');
      } else {
        let index = this.className.indexOf('isCollapsed');
        index >= 0 && this.className.splice(index, 1);
      }
    },
  },
  created () {
    this.$eventBus.$on('leftMenuCollapse', this.handleMenuCollapsChanged);
    if (this.direction && this.direction.toUpperCase() === 'RIGHT') {
      this.className.push('flex-right');
    } else if (this.direction && this.direction.toUpperCase() === 'MIDDLE') {
      this.className.push('flex-middle');
    }
    if (this.$local.get('leftMenuCollapse')) {
      this.className.push('isCollapsed');
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('leftMenuCollapse', this.handleMenuCollapsChanged);
  },
};
</script>
<style lang="scss" scoped>
.bottom_panel{
    .bottom_panel--placeholder{
      width: 100%;
      height: 60px;;
    }
    .bottom_panel--content{
      position: fixed;
      bottom: 0;
      right: 0;
      height: 60px;
      width: calc(100% - 220px);
      display: flex;
      justify-content: flex-end;
      padding: 10px;
      box-sizing: border-box;
      align-items: center;
      box-shadow: rgb(245, 245, 245) -10px -10px 35px;
      z-index: 100;
      background: white;

      &.isCollapsed {
        width: calc(100% - 65px);
      }

      &.flex-right{
          text-align: right;
      }

      &.flex-middle{
          text-align: center;
      }
    }
}
</style>
