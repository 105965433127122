import Vue from 'vue';
import Vuex from 'vuex';
import Auth from './auth';
import User from './user';
import common from './common';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Auth,
    User,
    common,
  },
});
