/*
 * @Date: 2021-09-29 10:21:46
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021-09-29 10:22:44
 * @Description: 通用数据
 * @FilePath: \sass_web\maintenance_expert\src\store\common\index.js
 */

import actions from './actions';
import getters from './getters';
import state from './state';
import mutations from './mutations';
export default {
  getters: getters,
  actions: actions,
  state: state,
  mutations: mutations,
};
