import server from '@/request';

// 获取公告列表
export const getAnnouncementLists = (params) => {
  return server.post('/xiaozi-seller/wireless/announcement/page', params);
};
// 获取公告详情
export const detailAnnouncement = (params) => {
  return server.post('/xiaozi-seller/wireless/announcement/detail', params);
};

// 用户关联供应商列表（我的企业）
export const getUserSupplierList = (params) => {
  return server.post('/xiaozi-seller/wireless/user/userSupplierList', params);
};

// 设置默认企业
export const setDefaultSupplier = (params) => {
  return server.post('/xiaozi-seller/wireless/user/setDefaultSupplier', params);
};
// 切换企业
export const switchSupplier = (params) => {
  return server.post('/xiaozi-seller/wireless/user/switchSupplier', params);
};
// 供应商商品分页列表
export const getSupplierGoodsPageList = (params) => {
  return server.post('/xiaozi-seller/wireless/goods/supplierGoodsPageList', params);
};
// 供应商商品分页列表导出
export const exportSupplierGoodsPageList = (params) => {
  return server.post('/xiaozi-seller/wireless/goods/exportSupplierGoodsPageList', params, {
    responseType: 'blob',
  });
};
// 调整库存
export const adjustStock = (params) => {
  return server.post('/xiaozi-seller/wireless/goods/adjustStock', params);
};
// 企业详情
export const detailSupplier = (params) => {
  return server.post('/xiaozi-seller/wireless/user/supplierDetail', params);
};
// 企业的员工列表
export const getSupplierUserList = (params) => {
  return server.post('/xiaozi-seller/wireless/user/supplierUserList', params);
};
// 添加供应商员工
export const addSupplierUser = (params) => {
  return server.post('/xiaozi-seller/wireless/user/addSupplierUser', params);
};
// 删除员工
export const delSupplierUser = (params) => {
  return server.post('/xiaozi-seller/wireless/user/delSupplierUser', params);
};
// 用户详情
export const detailUser = (params) => {
  return server.post('/xiaozi-seller/wireless/user/userDetail', params);
};

// 转让管理员
export const transferAdmin = (params) => {
  return server.post('/xiaozi-seller/wireless/user/transferAdmin', params);
};

// 功能描述：列表分页 销售对账记录
export const getRecordLists = (params) => {
  return server.post('/xiaozi-seller/wireless/reconciliation/queryPage', params);
};

// 功能描述：导出 销售对账记录
export const exportRecord = (params) => {
  return server.post('/xiaozi-seller/wireless/reconciliation/export', params, {
    responseType: 'blob',
  });
};
// 登录
export const logout = (params) => {
  return server.post('/xiaozi-seller/wireless/user/logout', params);
};
// 编辑用户
export const updateUser = (params)=>{
  return server.post('/xiaozi-seller/wireless/user/updateUser', params);
};
// 修改手机号
export const changePhone = (params)=>{
  return server.post('/xiaozi-seller/wireless/user/changePhone', params);
};
// 修改手机号
export const changePassword = (params)=>{
  return server.post('/xiaozi-seller/wireless/user/changePassword', params);
};

// 功能描述：采购统计

export const purchaseStatistics = (params)=>{
  return server.post('/xiaozi-seller/wireless/user/core/queryInfo', params);
};

// 根据父级id查询行政区域列表
export const getDistrictListByPid = (params)=>{
  return server.post('/xiaozi-seller/wireless/user/getDistrictListByPid', params);
};
