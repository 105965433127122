/*
 * @Date: 2021-09-29 10:21:46
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021-09-29 10:21:52
 * @Description: 共用数据action
 * @FilePath: \sass_web\maintenance_expert\src\store\common\actions.js
 */

export default {
  changeDefaultActive (context, defaultActiveName) {
    sessionStorage.setItem('defaultActiveName', defaultActiveName);
    context.commit('changeDefaultActive', defaultActiveName);
  },
  changeBreadLists (context, breadLists) {
    sessionStorage.setItem('breadLists', JSON.stringify(breadLists));
    context.commit('changeBreadLists', breadLists);
  },
};
