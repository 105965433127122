export default {
  currentUserInfo (state) {
    return state.currentUserInfo;
  },
  isLogin (state) {
    return state.currentUserInfo && state.currentUserInfo.loginUserId;
  },
  currentCompanyName (state) {
    let allCompanyLists = state.allCompanyLists;
    let currentSupplierId = state.currentSupplierId;
    let currentCompanyName = '';
    if (currentSupplierId && allCompanyLists.length) {
      currentCompanyName = allCompanyLists.filter(c=>c.supplierId === currentSupplierId)[0].supplierName;
    }
    return currentCompanyName;
  },
  currentUserName (state) {
    return state.userInfo.name;
  },
};
