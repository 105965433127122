<template>
<div class="font-normal check_tag">
    <slot></slot>
</div>
</template>
<script>
export default {
  name: 'CHECK_TAG',
  componentName: 'check_tag',
  props: {
    label: {
      type: String,
      require: true,
    },
    value: {
      type: String || Number || Object,
    },
  },
  data () {
    return {

    };
  },
  created () {
    this.$on('handleChange', value => {
      this.$emit('input', value);
      this.$emit('change', value);
    });
  },
};
</script>
<style lang="scss" scoped>
.check_tag{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
